import React from "react";
import PG_PP_INFO_SECTION_CONTENT from "./index.content";

const InfoSection = ({ currentLocale, localizationSwitch }) => {
  return (
    <div className="container PgTOU" id="home">
      <div className="row">
        <div className="col-12 mx-auto PgPP">
          <h1>PRIVACY POLICY</h1>
          <p>
            {localizationSwitch
              ? PG_PP_INFO_SECTION_CONTENT[currentLocale || "us"]?.t1()
              : PG_PP_INFO_SECTION_CONTENT.us.t1()}
          </p>
          <p>
            {localizationSwitch
              ? PG_PP_INFO_SECTION_CONTENT[currentLocale || "us"]?.t2()
              : PG_PP_INFO_SECTION_CONTENT.us.t2()}
          </p>
          <p>
            In addition to the other terms set forth herein, by accepting our
            Privacy Policy, you agree to participate in marketing, advertisement
            and or other legitimate business activities that are designed to
            promote AMZScout and its partners. AMZScout reserves the right to
            send our users information, advertisements and other types of
            messages in connection with these legitimate business activities. A
            user has the right to unsubscribe from such newsletters any time by
            contacting company support.
          </p>
          <h2 className="oldTitle">INFORMATION COLLECTION, DURATION & USE</h2>
          <p>
            While using our Site, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to, the following:
          </p>
          <ol className="PgPP__list">
            <li>Your name, address, email address, and/or phone number.</li>
            <li>
              Information regarding your use of our App and/or Site, and which
              website(s) you came to our Site from.
            </li>
            <li>
              Technical information about your device or browser when you use
              our App and/or Site, including geolocation data to determine what
              country you are accessing our App and/or Site from, your internet
              protocol (IP) address, device ID, browser type and version, and
              time zone setting, which may in some circumstances be personal
              data.
            </li>
          </ol>
          <p>
            We may also receive personal data directly from you or from various
            third parties and public sources, including from social media
            channels (if you interact with us through those channels), or other
            third parties.
          </p>
          <p>
            In the table below, we set out all of the ways we use your personal
            data, and why. We have also identified what our legitimate business
            interests are, where appropriate.
          </p>
        </div>
        <div className="col-12 table mx-auto">
          <table>
            <tr>
              <td>What</td>
              <td>How</td>
              <td>Why</td>
            </tr>
            <tr>
              <td>Communicating with you</td>
              <td>
                We use personal and contact information provided by you, copies
                of your communications with us and, in some circumstances,
                transaction details, to manage our relationship with you.
              </td>
              <td>
                Necessary to provide you with our featured services and
                necessary to comply with legal obligations.
              </td>
            </tr>
            <tr>
              <td>Experience</td>
              <td>
                We use information about your use of our Site, together with
                technical information about your device and your personal and
                contact information, and other relevant information that we
                receive from you or third parties (including aggregated
                information that we combine with your personal data), to deliver
                relevant website content and other marketing to you, and to
                manage the delivery of that content.
              </td>
              <td>
                Necessary for our legitimate interests (to ensure that you are
                shown relevant content based on your use of our App and/or
                Site).
              </td>
            </tr>
            <tr>
              <td>Advertising effectiveness</td>
              <td>
                We use information about your use of our App and/or Site and, in
                some circumstances, personal and contact information about you,
                to measure or understand the effectiveness of the advertising we
                serve to you.
              </td>
              <td>
                Necessary for our legitimate interests (to determine how
                effective advertising is in order to improve advertising, its
                relevance and in order to fulfill contracts we have with our
                marketing partners).
              </td>
            </tr>
            <tr>
              <td>Analytics</td>
              <td>
                We use information about your use of our Site and technical
                information, including about your device or where you are
                accessing our Site from, to optimize our services and to improve
                our Site, services, direct marketing, behavior profiling, and
                experiences.
              </td>
              <td>
                Necessary for our legitimate interests (to measure the
                interactions with our Site, to keep our Site updated and
                relevant, to develop our business and to inform our marketing
                strategy).
              </td>
            </tr>
            <tr>
              <td>Regulatory compliance</td>
              <td>
                We may use your personal and contact information, information
                about any transaction between you and us, information about your
                use of the Site, or technical information, to enable us to
                comply with our legal and regulatory obligations.
              </td>
              <td>Necessary to comply with legal obligations.</td>
            </tr>
            <tr>
              <td>Fraud prevention</td>
              <td>
                We may use your personal and contact information, information
                about any transaction between you and us, information about your
                use of the App and/or Site, or technical information, in order
                to undertake analysis for the purposes of identifying and
                dealing with any fraud or fraudulent activity. We do not store
                your credit card or other payment information.
              </td>
              <td>
                Necessary to comply with legal obligations. Necessary for our
                legitimate interests (to ensure that transactions and
                interactions with us are not fraudulent).
              </td>
            </tr>
            <tr>
              <td>Queries</td>
              <td>
                We may use your personal and contact information, information
                about any transaction between you and us, information about your
                use of the Site, or technical information for the purpose of
                dealing with any request, complaint, or query from you.
              </td>
              <td>
                Necessary to comply with legal obligations. Necessary for our
                legitimate interests (to respond to you and to deal with any
                request you may have).
              </td>
            </tr>
          </table>
        </div>
        <div className="col-12 PgPP mx-auto">
          <p>
            We use your personal data to create aggregated data sets. You are
            not identifiable from that aggregated data and it is not considered
            personal data in the manner that it is generally used. However, if
            we combine or connect aggregated data with your personal data in a
            way that results in your direct or indirect identification, we treat
            the combined data as personal data, and in accordance with this
            Privacy Policy.
          </p>
          <p>
            We send you marketing communications through a number of different
            channels. You may receive newsletters, email notifications, and
            other marketing communications that either you request from us or
            which you consent to by opting in when completing a registration
            form on our service, and/or if you do not ask, or you have not asked
            us to stop sending marketing by email to you.
          </p>
          <p>
            AMZScout does not sell User Information to third parties. However,
            we may share User Information with our staff, business partners, and
            third party service providers, for marketing, advertising or
            product/service offering purposes. For example, we provide User
            Information to our third party service providers for direct emailing
            of our newsletters, online surveys, or notifications about AMZScout
            offers. We also disclose User Information if: we have your consent
            and/or we need to share it in order to provide you with the products
            and/or services you requested. You may separately agree to provide
            your personal information to third parties that provide content for
            AMZScout offerings, in order to access and/or use their products
            and/or services. If you agree to provide such information to these
            third parties, then your personal information will be subject to the
            third parties’ privacy policies, which you are encouraged to review.
          </p>
          <p>
            You may opt out of receiving such marketing communications and
            notifications from us at any time. There are several ways you can do
            this. To stop receiving marketing by email: click{" "}
            <i>‘unsubscribe‘</i> or <i>‘update preferences’</i> in any email
            communication that we send; contact us in writing; or call us to opt
            out.
          </p>
          <p>
            We will only keep your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p>
            At the end of an applicable retention period, your data will either
            be deleted or anonymized (so that it can no longer be associated
            with you) for research or statistical purposes.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            your personal data, the purposes for which we process your personal
            data, whether we can achieve those purposes through other means, and
            any applicable legal requirements.
          </p>
          <h2 className="oldTitle">COOKIE POLICY</h2>
          <p>
            Cookies are small bits of text data placed on your device when you
            visit websites. Cookies record data about your web activity, such as
            which pages you view and what you click on. Cookies help our Site to
            recognize your device when you return. For example, cookies can help
            us to remember your username and your preferences, analyze the
            performance of our Site, and recommend content that may be the most
            relevant to your interests.
          </p>
          <p>Here are several reasons we may use cookies:</p>
          <ol className="PgPP__list">
            <li>
              <i>Analytics cookies</i>. These types of cookies show us which
              pages users view, which links are popular, etc. These cookies only
              provide anonymized information that does not identify any user
              personally. This information is then bundled with similar
              information from other users, so that we can analyze general usage
              patterns.
            </li>
            <li>
              <i>Essential cookies</i>. These are necessary for us to provide
              you with the services that you have asked for. Without these
              essential cookies, our Site would not be able to operate. They are
              necessary to enable users to navigate through the Site and use its
              main features, e.g., essential cookies identify registered users
              so that they can access member-only areas of the site. Essential
              cookies keep users logged in. If a user disables essential
              cookies, that user will not be able to access all of the content
              that a subscription entitles them to. These cookies do not collect
              information that could be utilized for the purposes of marketing
              or determining what places on the internet you have visited.
            </li>
            <li>
              <i>To show relevant advertising</i>. When we allow ads on our
              Site, they can contain cookies in order to provide ads most
              relevant to your interests. These cookies can also help to limit
              the number of times you see a particular ad and to assess the
              effectiveness of specific advertising campaigns.
            </li>
            <li>
              <i>To improve your browsing experience</i>. These types of cookies
              enable the Site to remember users’ preferences and settings, such
              as geographic region or language. They can also be used to
              restrict the number of times an ad is shown, or to remember which
              forms you have already filled in so that you do not have to enter
              all of the same information again.
            </li>
          </ol>
          <p>
            There is a way to turn off cookies by going to your browser’s Help,
            Settings, Preferences or similar menu. However, keep in mind that
            disabling cookies may limit your use of the Site and/or delay or
            affect the way in which it operates.
          </p>
          <h2 className="oldTitle">LINKS</h2>
          <p>
            The Site contains links to other sites. Please be aware that we are
            not responsible for the content or privacy practices of such other
            sites. We encourage our users to be aware of when they are leaving
            our Site and to read the privacy statements of any other sites that
            collect personally identifiable information.
          </p>
          <h2 className="oldTitle">
            CHILDREN UNDER THIRTEEN (13) YEARS OF AGE
          </h2>
          <p>
            If you are under the age of eighteen (18), you must ask your parent
            or guardian for permission to use our Site. AMZScout does not
            knowingly collect personally identifiable information from children
            under the age of thirteen (13). If we become aware that a child
            under 13 has provided any personal information to us, it will be
            erased from our database as soon as reasonably possible, except when
            we need to keep that information for legal purposes or to notify a
            parent or guardian.
          </p>
          <h2 className="oldTitle">YOUR RIGHTS</h2>
          <p>You have the right: </p>
          <ul>
            <li>to ask us not to use your personal data for marketing;</li>
            <li>
              to ask us not to process your personal data where it is processed
              on the basis of legitimate interests, if there are no compelling
              reasons for that processing;
            </li>
            <li>
              to request from us access to personal information held about you
              (see how to do this below);
            </li>
            <li>
              to ask for the information that we retain about you to be
              rectified if it is inaccurate or incomplete;
            </li>
            <li>
              to ask that we stop any consent-based processing of your personal
              data after you withdraw that consent;
            </li>
            <li>
              to ask, in certain circumstances, to delete any of your personal
              data that we retain;
            </li>
            <li>
              to ask, in certain circumstances, for the processing of that
              information to be restricted; and
            </li>
            <li>to ask, in certain circumstances, for data portability; and</li>
            <li>
              to ask us to take any and all such other measures in compliance
              with the EU’s General Data Protection Regulation (GDPR) if you are
              a user based in the European Union.
            </li>
          </ul>
          <h2 className="oldTitle">CHANGES TO THIS PRIVACY POLICY</h2>
          <p>
            {localizationSwitch
              ? PG_PP_INFO_SECTION_CONTENT[currentLocale || "us"]?.t3()
              : PG_PP_INFO_SECTION_CONTENT.us.t3()}
          </p>
          <div>
            {localizationSwitch
              ? PG_PP_INFO_SECTION_CONTENT[currentLocale || "us"]?.t5()
              : PG_PP_INFO_SECTION_CONTENT.us.t5()}
          </div>
          <h2 className="oldTitle">INTERNATIONAL TRANSFER</h2>
          <p>
            We process your personal information in the United States. This is
            where it will be transferred in case you are located somewhere else.
            By submitting any personal information to us, you agree to its
            transfer to and processing in the United States.
          </p>
          <h2 className="oldTitle">VISITOR CONSENT</h2>
          <p>
            By visiting or using our Site and/or App, you consent to this
            Privacy Policy.
          </p>
          <h2 className="oldTitle">CONTACT US</h2>
          <p>
            AMZScout welcomes your questions or comments regarding this Privacy
            Policy. If you believe that AMZScout has not adhered to this Privacy
            Policy, please contact AMZScout at:
          </p>
          <div>
            {localizationSwitch
              ? PG_PP_INFO_SECTION_CONTENT[currentLocale || "us"]?.t4()
              : PG_PP_INFO_SECTION_CONTENT.us.t4()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
